import styled from '@emotion/styled'
import { Box, type SxProps } from '@mui/material'
import { spacing, Text } from '@orus.eu/pharaoh'

import type { ReactElement, ReactNode } from 'react'
import { useLargeScreen } from '../../lib/use-large-screen'
import { HelpIcon } from '../molecules/help-icon'

export type LabeledControlProps = {
  sx?: SxProps
  disabled?: boolean
  children: ReactNode
  label: string
  /**
   * Adds an info icon with the hint as tooltip
   */
  hint?: string

  className?: string
}

/**
 * @deprecated Use `TextInputLabelWrapper` instead
 */
export function WithLabel({ sx = {}, children, label, disabled, hint, className }: LabeledControlProps): ReactElement {
  const largeScreen = useLargeScreen()

  return (
    <Box sx={sx} className={className}>
      <StyledText variant="body2Medium" largeScreen={largeScreen} disabled={!!disabled} element="label">
        <span> {label}</span>
        {hint ? <HelpIcon title={hint} /> : <></>}
      </StyledText>
      {children}
    </Box>
  )
}

const StyledText = styled(Text)<{ largeScreen: boolean; disabled: boolean }>`
  margin-bottom: ${spacing[20]};
  display: flex;
  gap: ${spacing[30]};
  justify-content: ${({ largeScreen }) => (largeScreen ? undefined : 'space-between')};
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`
